import { IFrameClient } from '@remento-infrastructure/common/iframe.client.js';

import { Debugger, DebuggerConfig, DebuggerEvent } from './debugger.js';
import { Router } from './router.js';

export const IFrameDebugPayloadRequest = 'iframe-debug-payload-request';

export class DebuggerIframe extends Debugger {
  constructor(locationId: string, config: DebuggerConfig, router: Router, private iframeClient: IFrameClient) {
    super(locationId, config, router);
  }

  protected async transformEvent(event: DebuggerEvent): Promise<DebuggerEvent> {
    return this.iframeClient.request<DebuggerEvent, DebuggerEvent>(
      IFrameDebugPayloadRequest,
      await super.transformEvent(event),
    );
  }
}
