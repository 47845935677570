import logger from '@remento-infrastructure/common/logger';

import { AnalyticsContext } from '../../@types.js';
import { Payload } from '../../router.js';

import { EmailFormSubmitEvent, EmailFormSubmitPayload } from './form.types.js';

const HANDLER_ID = 'form';

export function registerEmailFormHandler(context: AnalyticsContext) {
  context.router.registerHandler(HANDLER_ID, async (event: string, payload: Payload<unknown>) => {
    switch (event) {
      case EmailFormSubmitEvent: {
        const eventPayload = payload as Payload<EmailFormSubmitPayload>;
        const formId = `${context.config.clientSource}.${payload.properties.pageId}.${eventPayload.data.formType}-form`;
        logger.debug(`[HANDLER - FORM] Received event ${EmailFormSubmitEvent}`, eventPayload);
        await context.client.identifyWithEmail(eventPayload.data.email, formId);
        await context.client.track('website.form', {
          form: eventPayload.data.formType,
          formId: formId,
          ...eventPayload.data,
          ...eventPayload.properties,
        });
        return true;
      }
    }
    return false;
  });
}
