import { IFrameClient } from '@remento-infrastructure/common/iframe.client.js';

import { Debugger, DebuggerConfig } from './debugger.js';
import { Router } from './router.js';

export const IFrameConfigRequest = 'iframe-config-request';

export type InitIFrameCallback = (context: IFrameAnalyticsContext) => void;

export interface IFrameAnalyticsConfig {
  debugConfig?: DebuggerConfig | null;
  loggerLevel: string | null;
}

export interface IFrameAnalyticsContext {
  router: Router;
  iframeClient: IFrameClient;
  config: IFrameAnalyticsConfig;
  debugger?: Debugger;
}
