export async function waitForProperty(obj: Record<string, any>, property: string): Promise<void> {
  if (obj[property]) {
    return;
  }
  return new Promise<void>((resolve) => {
    let value: any = undefined;
    Object.defineProperty(obj, property, {
      get() {
        return value;
      },
      set(v) {
        value = v;
        resolve();
      },
      enumerable: true,
      configurable: true,
    });
  });
}

export async function waitForPromise<T>(promise: Promise<T>, timeout: number | null = null): Promise<T> {
  if (timeout == null) {
    return await promise;
  }
  return await Promise.race([
    promise,
    new Promise<T>((_, reject) => setTimeout(() => reject(new Error('promise-timeout')), timeout)),
  ]);
}
