import { AnalyticsConfig } from '@remento-infrastructure/analytics-integrations/@types.js';
import { getRuntimeConfig, initializeAnalytics } from '@remento-infrastructure/analytics-integrations/init.js';
import { registerEmailFormHandler } from '@remento-infrastructure/analytics-integrations/sources/form/form-handler.js';
import { registerWisepopsEmailFormsObserver } from '@remento-infrastructure/analytics-integrations/sources/form/form-observer-wisepops.js';
import { registerCookiesTransform } from '@remento-infrastructure/analytics-integrations/transforms/cookies.js';
import { registerPageTransform } from '@remento-infrastructure/analytics-integrations/transforms/page.js';
import logger from '@remento-infrastructure/common/logger.js';
import { waitForProperty } from '@remento-infrastructure/integrations/initialization.js';
import { waitForWisepopsLoaded } from '@remento-infrastructure/integrations/wisepops.js';
import { AnalyticsBrowser } from '@segment/analytics-next';

const ANALYTICS_CONFIG: AnalyticsConfig = {
  clientSegmentKey: 'nope',
  clientSource: 'remento-book-webapp',
  clientAPIHost: 'segment.remento.co',
  clientCDNUrl: 'https://segment-cdn.remento.co',
  debugConfig: getRuntimeConfig('debug', null) == null ? null : {},
  loggerLevel: getRuntimeConfig('logger-level', null),
};

declare global {
  interface Window {
    SEGMENT_INSTANCE?: AnalyticsBrowser;
  }
}

// Keep track of the current URL and poll to update the analytics page property
let location: string | null = null;
setInterval(() => {
  if (location == window.location.href) {
    return;
  }
  if (window.location.pathname.startsWith('/questionnaire')) {
    window.ANALYTICS_PAGE = 'questionnaire';
  } else {
    window.ANALYTICS_PAGE = 'unknown';
  }
  location = window.location.href;
}, 200);

waitForProperty(window, 'SEGMENT_INSTANCE').then(() => {
  ANALYTICS_CONFIG.client = window.SEGMENT_INSTANCE;
  initializeAnalytics(ANALYTICS_CONFIG, undefined, (context) => {
    registerCookiesTransform(context.router);
    registerPageTransform(context.router, ANALYTICS_CONFIG.clientSource);
    registerEmailFormHandler(context);

    waitForWisepopsLoaded().then(() => registerWisepopsEmailFormsObserver(context.router, context.debugger));
  });
});
