import logger from '@remento-infrastructure/common/logger';

export interface Payload<T> {
  data: T;
  properties: Record<string, string | number | boolean | undefined | null | Array<string>>;
}

export type Transform = <T = unknown>(event: string, payload: Payload<T>) => Payload<T>;
export type Handler = <T = unknown>(event: string, payload: Payload<T>) => Promise<boolean> | boolean;

export class Router {
  private plugins = new Map<string, unknown>();
  private publisherIds: Array<string> = [];
  private transformIds: Array<string> = [];
  private transforms: Array<Transform> = [];
  private handlerIds: Array<string> = [];
  private handlers: Array<Handler> = [];

  hasPlugin<T>(id: string): boolean {
    return this.plugins.has(id);
  }

  getPlugin<T>(id: string): T | null {
    return (this.plugins.get(id) as T) ?? null;
  }

  registerPlugin<T>(id: string, plugin: T): boolean {
    if (this.plugins.has(id)) {
      console.warn(`The plugin ${id} is already registered`);
      return false;
    }
    this.plugins.set(id, plugin);
    return true;
  }

  registerPublisher(id: string): boolean {
    if (this.publisherIds.indexOf(id) !== -1) {
      console.warn(`The publisher ${id} is already registered`);
      return false;
    }
    this.publisherIds.push(id);
    return true;
  }

  registerTransform(id: string, transform: Transform): boolean {
    if (this.transformIds.indexOf(id) !== -1) {
      console.warn(`The transform ${id} is already registered`);
      return false;
    }
    this.transformIds.push(id);
    this.transforms.push(transform);
    return true;
  }

  registerHandler(id: string, handler: Handler): boolean {
    if (this.handlerIds.indexOf(id) !== -1) {
      console.warn(`The handler ${id} is already registered`);
      return false;
    }
    this.handlerIds.push(id);
    this.handlers.push(handler);
    return true;
  }

  transform<T>(event: string, payload: Payload<T>): Payload<T> {
    return this.transforms.reduce((payload, t) => t(event, payload), payload);
  }

  async publish<T>(event: string, payload: Payload<T>, transform = true) {
    const _payload = transform ? this.transform(event, payload) : payload;
    for (let i = 0; i < this.handlers.length; i++) {
      const handler = this.handlers[i];
      const handlerID = this.handlerIds[i];
      const handled = await handler(event, _payload);
      if (handled) {
        logger.debug(`[ROUTER] Handler ${handlerID} handled event ${event}`, payload);
        return true;
      } else {
        logger.trace(`[ROUTER] Handler ${handlerID} skipped event ${event}`, payload);
      }
    }
    return false;
  }
}
