import { Router } from '../router.js';

export function registerPageTransform(router: Router, source?: string) {
  router.registerTransform('page', (event, payload) => {
    return {
      ...payload,
      properties: {
        source,
        pageId: window.ANALYTICS_PAGE,
        ...window.ANALYTICS_PROPERTIES,
        ...payload.properties,
      },
    };
  });
}
