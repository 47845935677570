import { BrowserAnalyticsClient } from '@remento-infrastructure/analytics-client/client.js';

import { Payload, Router } from './router.js';

export interface DebuggerEvent {
  type: string;
  payload: Payload<any>;
  identify?: string;
}

export interface DebuggerTooltipEvent {
  type: string | null;
  payload: Payload<any> | null;
  identify?: string;
}

export interface DebuggerTooltipConfig {
  elements: Array<HTMLElement>;
  getEvent: ($element: HTMLElement) => DebuggerTooltipEvent;
}
export type DebuggerTooltipConfigFactory = () => DebuggerTooltipConfig;

export interface DebuggerConfig {
  bootstrap?: {
    url: string;
    namespace: string;
  } | null;
}

export class Debugger {
  private tooltips = new Map<string, DebuggerTooltipConfigFactory>();
  private initialized = false;
  private $bootstrapNamespace?: HTMLElement;

  constructor(
    private locationId: string,
    private config: DebuggerConfig,
    private router: Router,
    private client?: BrowserAnalyticsClient,
  ) {}

  registerTooltips(id: string, factory: DebuggerTooltipConfigFactory): boolean {
    if (this.tooltips.has(id)) {
      console.warn(`The debug config factory ${id} is already registered`);
      return false;
    }
    this.tooltips.set(id, factory);
    return true;
  }

  private async insertExternalScript(src: string) {
    const $script = document.createElement('script');
    $script.src = src;
    const promise = new Promise((resolve) => $script.addEventListener('load', resolve));
    document.body.appendChild($script);
    await promise;
  }

  private async insertExternalStyle(href: string) {
    const $link = document.createElement('link');
    $link.rel = 'stylesheet';
    $link.href = href;
    const promise = new Promise((resolve) => $link.addEventListener('load', resolve));
    document.head.appendChild($link);
    await promise;
  }

  private insertStyle(content: string) {
    const $style = document.createElement('style');
    $style.innerHTML = content;
    document.head.appendChild($style);
  }

  private createBootstrapNamespace(create = false): HTMLElement | undefined {
    const $body = document.querySelector('body');
    if ($body == null) {
      return undefined;
    }
    if (this.config?.bootstrap?.namespace == null) {
      if (!create) {
        return $body;
      }
      const $namespace = document.createElement('div');
      $body.appendChild($namespace);
      return $namespace;
    }
    const $namespace = document.createElement('div');
    $namespace.classList.add(this.config.bootstrap.namespace);
    $namespace.setAttribute('data-bs-theme', 'light');
    $body.appendChild($namespace);
    return $namespace;
  }

  protected async transformEvent(event: DebuggerEvent): Promise<DebuggerEvent> {
    return {
      ...event,
      payload: this.router.transform(event.type, event.payload),
    };
  }

  private renderTooltipContent(event: DebuggerEvent): string {
    const analyticsEventProperties: Record<string, any> = {
      ...event.payload.properties,
      ...event.payload.data,
    };
    const tooltipContent = [];
    if (event.identify !== undefined) {
      tooltipContent.push(`<b>Identify</b>: ${event.identify}`);
    }
    tooltipContent.push(
      `<b>Event</b>: ${event.type} {`,
      ...Object.keys(analyticsEventProperties)
        .sort()
        .filter((k) => analyticsEventProperties[k] != null)
        .map((k) => `  ${k}: ${analyticsEventProperties[k]}`),
      '}',
    );
    return tooltipContent.join('<br>').replaceAll(' ', '&nbsp;');
  }

  initTooltip($element: HTMLElement, getEvent: ($element: HTMLElement) => DebuggerTooltipEvent) {
    $element.classList.add('debugger-tooltip-anchor');
    const tooltip = new window.bootstrap.Tooltip($element, {
      html: true,
      container: this.$bootstrapNamespace,
      trigger: 'manual',
      placement: 'bottom',
      title: 'Loading...',
    });
    let cancelled = false;
    let active = false;
    $element.addEventListener('mouseover', async () => {
      if (active) {
        return;
      }
      cancelled = false;
      active = true;
      const tooltipEvent = getEvent($element);
      if (tooltipEvent.type == null || tooltipEvent.payload == null) {
        tooltip.setContent({ ['.tooltip-inner']: 'The element generated an invalid event. No data will be sent.' });
        tooltip.show();
        return;
      }
      const event = tooltipEvent as DebuggerEvent;
      const tooltipContent = this.renderTooltipContent(await this.transformEvent(event));
      if (cancelled) {
        return;
      }
      tooltip.setContent({ ['.tooltip-inner']: tooltipContent });
      tooltip.show();
    });
    $element.addEventListener('mouseleave', async () => {
      if (!active) {
        return;
      }
      cancelled = true;
      active = false;
      tooltip.hide();
    });
  }

  private initTooltips() {
    this.insertStyle(`
      .debugger-tooltip-anchor {
        border: 1px solid red!important;
      }
      .tooltip .tooltip-inner {
        text-align: left;
        max-width: 500px;
      }
    `);
    const configs = Array.from(this.tooltips.values()).map((f) => f());
    for (const config of configs) {
      config.elements.forEach(($element) => this.initTooltip($element, config.getEvent));
    }
  }

  private renderStatusBar($container: HTMLElement) {
    const statusBarContent = [`<b>LocationId</b>: ${this.locationId}`];
    if (this.client) {
      statusBarContent.push(`<b>Identity</b>: ${this.client?.getId() ?? 'Anonymous'}`);
      const traits = this.client?.getTraits();
      if (traits != null) {
        if (Object.keys(traits).length == 0) {
          statusBarContent.push(`<b>Identity traits</b> N/A`);
        } else {
          statusBarContent.push(
            `<b>Identity traits</b> {`,
            ...Object.keys(traits).map((k) => `  ${k}: ${traits[k]}`),
            '}',
          );
        }
      }
    }
    $container.innerHTML = statusBarContent.join('<br>').replaceAll(' ', '&nbsp;');
  }

  private initStatusBar() {
    this.insertStyle(`
      .debugger-status {
            position: fixed;
            left: 0;
            bottom: 0;
            background-color: red;
            width: 100%;
            max-width: 500px;
      }
    `);
    const $bootstrapNamespace = this.createBootstrapNamespace(true);
    if ($bootstrapNamespace == null) {
      return;
    }
    $bootstrapNamespace.classList.add('debugger-status');
    this.renderStatusBar($bootstrapNamespace);
    this.client?.onIdentify(() => this.renderStatusBar($bootstrapNamespace));
  }

  async init() {
    if (this.initialized) {
      return console.warn('The debugger has already been initialized');
    }
    await this.insertExternalStyle(
      this.config?.bootstrap?.url ?? 'https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css',
    );
    if (!('jQuery' in window)) {
      await this.insertExternalScript('https://code.jquery.com/jquery-3.7.1.min.js');
    }
    await this.insertExternalScript('https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js');
    await this.insertExternalScript('https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js');

    this.$bootstrapNamespace = this.createBootstrapNamespace();

    this.initTooltips();
    this.initStatusBar();
  }
}
