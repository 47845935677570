import logger from '@remento-infrastructure/common/logger';
import { AnalyticsBrowser, AnalyticsBrowserSettings, InitOptions, Plugin, User } from '@segment/analytics-next';

export type EventProperties = Record<string, any>;
export type IdentifyObserver = (id: string, traits?: Record<string, any>) => void;

export interface UserProperties {
  email?: string;
  phone?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
}

interface IDResponse {
  id: string;
}

interface RefreshIDResponse {
  id: string | null;
}

interface LinkResponse extends IDResponse {
  status: 'NEW' | 'NOOP' | 'UNAVAILABLE';
}

export interface SegmentOptions {
  key: string;
  settings?: Omit<AnalyticsBrowserSettings, 'writeKey'>;
  options: InitOptions;
}

export interface KlaviyoOptions {
  key: string;
  list: string;
}

export class BrowserAnalyticsClient {
  private id: string | null = null;
  private traits: Record<string, any> | null = null;
  private analytics: AnalyticsBrowser;
  private identifyObservers = new Set<IdentifyObserver>();

  constructor(segment: AnalyticsBrowser, private source: string) {
    this.analytics = segment;
  }

  private async generateIdWithEmail(email: string, source: string): Promise<string> {
    if (email.length === 0) {
      throw new Error('Invalid email provided');
    }
    const user = await this.analytics.user();
    const userId = user.id();
    if (user.id() !== null) {
      const response = await fetch(`https://remento-id-server-orweg4rrra-uc.a.run.app/id/${userId}/link`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ channel: 'email', value: email }),
      });
      const result: LinkResponse = await response.json();
      if (result.status == 'NEW') {
        await this.analytics.track('link-email', {
          email: email,
        });
      } else if (result.status == 'UNAVAILABLE') {
        await this.analytics.track('link-email.conflict', {
          userId: result.id,
          email: email,
        });
      }
      return result.id;
    } else {
      const response = await fetch(
        `https://remento-id-server-orweg4rrra-uc.a.run.app/id/email/${email}?source=${source}`,
      );
      const result: IDResponse = await response.json();
      return result.id;
    }
  }

  private async getUpdatedRUID(user: User): Promise<string | null> {
    const url = new URL(document.URL);
    const urlRUID = url.searchParams.get('utm_ruid');
    const ruid = urlRUID ?? user.id();
    if (ruid == null) {
      return null;
    }
    const response = await fetch(`https://remento-id-server-orweg4rrra-uc.a.run.app/id/${ruid}`);
    const result: RefreshIDResponse = await response.json();
    return result.id;
  }

  async initialize(plugins?: Array<Plugin>): Promise<void> {
    plugins?.forEach((p) => this.analytics.register(p));

    const user = await this.analytics.user();
    const ruid = await this.getUpdatedRUID(user);
    if (ruid === user.id()) {
      this.id = user.id() ?? null;
      this.traits = user.traits() ?? null;
    } else if (ruid !== null) {
      await this.analytics.identify(ruid);
      this.id = ruid;
      this.traits = (await this.analytics.user()).traits() ?? null;
    } else {
      await this.analytics.reset();
      this.id = null;
      this.traits = null;
    }
    logger.info(`[ANALYTICS CLIENT] Initializing: ${this.id}`, user.traits());
  }

  async identifyWithEmail(email: string, source: string, properties?: Omit<UserProperties, 'email'>) {
    const id = await this.generateIdWithEmail(email, source);
    this.id = id;

    const traits = {
      ...properties,
      email,
      name:
        properties?.name ?? (properties?.firstName != null && properties?.lastName != null)
          ? `${properties.firstName} ${properties.lastName}`
          : undefined,
    };
    await this.analytics.identify(this.id, traits);
    const user = await this.analytics.user();
    this.traits = user.traits() ?? null;
    this.identifyObservers.forEach((cb) => cb(id, user.traits()));
    logger.info(`[ANALYTICS CLIENT] Identify:  ${this.id}`, traits);
  }

  isIdentified(): boolean {
    return this.id != null;
  }

  getId(): string | null {
    return this.id;
  }

  getTraits(): Record<string, any> | null {
    return this.traits;
  }

  onIdentify(callback: IdentifyObserver) {
    this.identifyObservers.add(callback);
  }

  async page(name?: string, properties?: EventProperties) {
    logger.info(`[ANALYTICS CLIENT] Track: ${name}.arrive`, properties);
    await this.analytics.page(name ?? 'unknown', { ...properties, source: this.source });
  }

  async track(event: string, properties?: EventProperties) {
    logger.info(`[ANALYTICS CLIENT] Track: ${event}`, properties);
    await this.analytics.track(event, { ...properties, source: this.source });
  }
}
