import { waitForProperty } from './initialization.js';

export async function waitForWisepopsLoaded(timeout: number | null = 2000): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.wisepops) {
    return;
  }
  await waitForProperty(window, 'wisepops');
}
