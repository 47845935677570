import { datadogRum } from '@datadog/browser-rum';
import { Context, Plugin } from '@segment/analytics-next';

export class DatadogSegmentPlugin implements Plugin {
  name = 'Datadog';
  version = '0.1.0';
  readonly type = 'destination';

  isLoaded() {
    return true;
  }
  async load() {
    return Promise.resolve();
  }

  async track(ctx: Context): Promise<Context> {
    if (ctx.event.event) {
      datadogRum.addAction(ctx.event.event, ctx.event.properties);
    }
    return ctx;
  }

  async identify(ctx: Context): Promise<Context> {
    const userId = ctx.event.userId;
    if (userId != null) {
      datadogRum.setUser({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: userId,
        ...ctx.event.traits,
      });
    }
    return ctx;
  }
}
