import Cookies from 'js-cookie';

import { Router } from '../router';
import { PageInitActivityEvent } from '../sources/activity/activity.types';

export function getMarketingCookies() {
  const facebookClickId = Cookies.get('_fbc') ?? null;
  const facebookDeviceId = Cookies.get('_fbp') ?? null;
  return { facebookClickId, facebookDeviceId };
}

export function registerCookiesTransform(router: Router) {
  router.registerTransform('marketing', (event, payload) => {
    if (event !== PageInitActivityEvent) {
      return payload;
    }
    return {
      ...payload,
      properties: {
        ...getMarketingCookies(),
      },
    };
  });
}
