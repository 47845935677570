export const LoggerLevel = {
  trace: -1,
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
};

export class Logger {
  private level = LoggerLevel.error;

  setLevel(level: keyof typeof LoggerLevel | string) {
    if (level in LoggerLevel) {
      this.level = LoggerLevel[level as keyof typeof LoggerLevel];
    }
  }

  trace(...args: any[]) {
    if (this.level <= LoggerLevel.trace) console.trace(...args);
  }

  debug(...args: any[]) {
    if (this.level <= LoggerLevel.debug) console.debug(...args);
  }

  info(...args: any[]) {
    if (this.level <= LoggerLevel.info) console.info(...args);
  }

  warn(...args: any[]) {
    if (this.level <= LoggerLevel.warn) console.warn(...args);
  }

  error(...args: any[]) {
    if (this.level <= LoggerLevel.error) console.error(...args);
  }
}

export default new Logger();
