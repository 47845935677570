import {
  IFrameMessage,
  IFrameResponseAckMessage,
  IFrameResponseErrorMessage,
  IFrameResponseSuccessMessage,
} from './iframe.types.js';
import logger from './logger.js';

export type IframeServerHandler = (message: any) => Promise<any>;

const IGNORED_MESSAGES = ['iframe-req-ack', 'iframe-req-success', 'iframe-req-error'];

export class IframeServer {
  private handlers = new Map<string, IframeServerHandler>();
  constructor(private domains: Array<string>) {}

  addHandler(type: string, handler: IframeServerHandler) {
    this.handlers.set(type, handler);
  }

  run() {
    window.addEventListener('message', async (event: MessageEvent) => {
      const url = new URL(event.origin);
      if (!this.domains.some((h) => url.hostname.endsWith(h))) {
        return;
      }
      try {
        const reqMessage = JSON.parse(event.data) as IFrameMessage;
        // Do not try to ack these messages. This will create a infinite message loop.
        if (IGNORED_MESSAGES.includes(reqMessage.type)) {
          return;
        }

        logger.debug(`[IFRAME SERVER] Request - ${reqMessage.id}- ${reqMessage.type}`, reqMessage.payload);
        const handler = this.handlers.get(reqMessage.type);
        logger.debug(`[IFRAME SERVER] Request ack - ${reqMessage.id}- ${reqMessage.type}`);
        const ackMessage: IFrameResponseAckMessage = {
          id: reqMessage.id,
          type: 'iframe-req-ack',
          payload: {
            handled: handler != null,
          },
        };
        event.source?.postMessage(JSON.stringify(ackMessage), {
          targetOrigin: event.origin,
        });
        if (handler == null) {
          return logger.warn('[IFRAME SERVER] No handler registered for message', reqMessage);
        }
        try {
          const response = await handler(reqMessage.payload);
          logger.debug(`[IFRAME SERVER] Request response- ${reqMessage.id} - ${reqMessage.type}`, response);
          const responseMessage: IFrameResponseSuccessMessage = {
            id: reqMessage.id,
            type: 'iframe-req-success',
            payload: response,
          };
          event.source?.postMessage(JSON.stringify(responseMessage), {
            targetOrigin: event.origin,
          });
        } catch (err) {
          const responseMessage: IFrameResponseErrorMessage = {
            id: reqMessage.id,
            type: 'iframe-req-error',
            payload: err as Error,
          };
          logger.warn('[IFRAME SERVER] Failed to handle message', err, event);
          event.source?.postMessage(JSON.stringify(responseMessage), {
            targetOrigin: event.origin,
          });
        }
      } catch (err) {
        logger.warn('[IFRAME SERVER] Failed to parse message', err, event);
      }
    });
  }
}
