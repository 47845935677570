import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { BrowserAnalyticsClient } from '@remento-infrastructure/analytics-client/client.js';
import { DatadogSegmentPlugin } from '@remento-infrastructure/analytics-client/plugins/datadog.js';
import { IframeServer } from '@remento-infrastructure/common/iframe.server.js';
import logger from '@remento-infrastructure/common/logger';
import { waitForDocumentLoaded } from '@remento-infrastructure/integrations/document.js';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { AnalyticsConfig, AnalyticsContext, InitCallback } from './@types.js';
import { IFrameAnalyticsConfig, IFrameConfigRequest } from './@types-iframe.js';
import { Debugger, DebuggerEvent } from './debugger.js';
import { IFrameDebugPayloadRequest } from './debugger-iframe.js';
import { Router } from './router.js';

export function getRuntimeConfig(key: string, value: string | null): string | null {
  const url = new URL(document.URL);
  return url.searchParams.get(key) ?? value;
}

function initializeAnalyticsModules(callback: InitCallback) {
  if (window.rementoAnalyticsContext) {
    return callback(window.rementoAnalyticsContext);
  }
  if (window.ANALYTICS_INITIALIZERS == null) {
    window.ANALYTICS_INITIALIZERS = [];
  }
  window.ANALYTICS_INITIALIZERS.push(callback);
}

async function initializeAnalyticsContext(
  analyticsConfig: AnalyticsConfig,
  datadogConfig?: RumInitConfiguration,
): Promise<AnalyticsContext> {
  if (window.rementoAnalyticsContext) {
    return window.rementoAnalyticsContext;
  }
  if (datadogConfig != null) {
    datadogRum.init(datadogConfig);
    datadogLogs.init({
      clientToken: datadogConfig.clientToken,
      site: datadogConfig.site,
      service: datadogConfig.service,
      env: datadogConfig.env,
      version: datadogConfig.version,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sessionSampleRate: datadogConfig.sessionSampleRate,
    });
    datadogRum.startSessionReplayRecording();
  }
  const segment =
    analyticsConfig.client ??
    AnalyticsBrowser.load(
      { writeKey: analyticsConfig.clientSegmentKey, cdnURL: analyticsConfig.clientCDNUrl },
      { integrations: { 'Segment.io': { apiHost: `${analyticsConfig.clientAPIHost}/v1` } } },
    );
  const client = new BrowserAnalyticsClient(segment, analyticsConfig.clientSource);
  const router = new Router();
  window.rementoAnalyticsContext = { router, client, config: analyticsConfig };
  window.rementoLogger = logger;

  logger.setLevel(analyticsConfig.loggerLevel ?? (analyticsConfig.debugConfig != null ? 'info' : 'warn'));

  if (analyticsConfig.iframeServerConfig != null) {
    window.rementoAnalyticsContext.iframeServer = new IframeServer(analyticsConfig.iframeServerConfig.domains);
    window.rementoAnalyticsContext.iframeServer.run();
  }
  if (analyticsConfig.debugConfig) {
    window.rementoAnalyticsContext.debugger = new Debugger(
      `${analyticsConfig.clientSource}.${window.ANALYTICS_PAGE}`,
      analyticsConfig.debugConfig,
      router,
      client,
    );
    window.rementoAnalyticsContext.iframeServer?.addHandler(
      IFrameDebugPayloadRequest,
      async (event: DebuggerEvent): Promise<DebuggerEvent> => ({
        ...event,
        payload: router.transform(event.type, event.payload),
      }),
    );
  }
  window.rementoAnalyticsContext.iframeServer?.addHandler(
    IFrameConfigRequest,
    async (): Promise<IFrameAnalyticsConfig> => ({
      debugConfig: analyticsConfig.debugConfig,
      loggerLevel: analyticsConfig.loggerLevel,
    }),
  );
  logger.debug('[INIT] Initializing');

  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!/
  //                 WARNING
  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!/
  //
  // Async code can only live below this line
  //
  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!/
  try {
    await client.initialize([new DatadogSegmentPlugin()]);
    logger.debug('[INIT] Initialized analytics client');
  } catch (err) {
    logger.debug('[INIT] Failed to initialize the client', err);
  }
  return window.rementoAnalyticsContext;
}

export function initializeAnalytics(
  analyticsConfig: AnalyticsConfig,
  datadogConfig?: RumInitConfiguration,
  callback?: InitCallback,
) {
  if (callback) {
    initializeAnalyticsModules(callback);
  }
  waitForDocumentLoaded()
    .then(() => initializeAnalyticsContext(analyticsConfig, datadogConfig))
    .then((context) => {
      if (window.ANALYTICS_INITIALIZERS != null) {
        window.ANALYTICS_INITIALIZERS.forEach((cb) => cb(context));
      }
      return context;
    })
    .then((context) => context.debugger?.init());
}
